<template>
  <b-container fluid>
      <b-row>
        <b-col lg="12" sm="12">
          <iq-card>
            <template>
                <b-row>
                  <b-col lg="12" sm="12">
                    <b-row>
                      <b-col lg="12" sm="12">
                        <div>
                          <b-table-simple bordered>
                            <b-tr>
                                <b-th>{{ $t('educational_management.name') }}</b-th>
                                <b-td>{{ ($i18n.locale=='bn') ? detailsData.name_bn : detailsData.name }}</b-td>
                                <b-th>{{ $t('user.mobile_no') }}</b-th>
                                <b-td>{{ ($i18n.locale == 'bn' ? '০': '0') + $n(detailsData.mobile_no, { useGrouping: false }) }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th>{{ $t('externalUserIrrigation.father_name') }}</b-th>
                                <b-td>{{ ($i18n.locale=='bn') ? detailsData.father_name_bn : detailsData.father_name }}</b-td>
                                <b-th>{{ $t('externalUserIrrigation.mother_name') }}</b-th>
                                <b-td>{{ ($i18n.locale=='bn') ? detailsData.mother_name_bn : detailsData.mother_name }}</b-td>
                            </b-tr>
                            <b-tr>
                              <b-th>{{ $t('externalUserIrrigation.date_of_birth') }}</b-th>
                              <b-td>{{ detailsData.date_of_birth | dateFormat }}</b-td>
                              <b-th>{{ $t('admission_form.nid_no') }}</b-th>
                              <b-td>{{ $n(detailsData.nid_no, { useGrouping: false }) }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th v-if="detailsData.email">{{ $t('user.email') }}</b-th>
                                <b-td v-if="detailsData.email">{{ detailsData.email }}</b-td>
                                <b-th v-if="detailsData.user_id">{{ $t('farm_config.user_id') }}</b-th>
                                <b-td v-if="detailsData.user_id">{{ $n(detailsData.user_id, { useGrouping: false }) }}</b-td>
                            </b-tr>
                          </b-table-simple>
                        </div>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </template>
              <template>
                <div style="font-size:18px; background-color: var(--brand-color);">
                <h5 class="text-white text-center">
                    {{ $t('admission_form.add_info') }}
                </h5>
                </div>
            </template>
            <b-table-simple bordered>
              <b-tr>
                  <b-th colspan="6" class="text-center">{{ $t('educational_management.present_business_address') }}</b-th>
              </b-tr>
              <b-tr>
                  <b-th v-if="detailsData.area_type_id">{{ $t('org_pro.area_type') }}</b-th>
                  <b-td v-if="detailsData.area_type_id">{{ getAreaType(detailsData.area_type_id) }}</b-td>
                  <b-th v-if="detailsData.division_id">{{ $t('org_pro_division.division') }}</b-th>
                  <b-td v-if="detailsData.division_id">{{ getDivisionName(detailsData.division_id) }}</b-td>
                  <b-th v-if="detailsData.district_id">{{ $t('org_pro_district.district') }}</b-th>
                  <b-td v-if="detailsData.district_id">{{ getDistrictName(detailsData.district_id) }}</b-td>
              </b-tr>
              <b-tr>
                  <b-th v-if="detailsData.upazila_id">{{ $t('org_pro_upazilla.upazilla') }}</b-th>
                  <b-td v-if="detailsData.upazila_id">{{ getUpazilaName(detailsData.upazila_id )}}</b-td>
                  <b-th v-if="detailsData.union_id">{{ $t('org_pro_union.union') }}</b-th>
                  <b-td v-if="detailsData.union_id">{{ getUnionName(detailsData.union_id) }}</b-td>
                  <b-th v-if="detailsData.city_corporation_id">{{ $t('org_pro.city_corporation') }}</b-th>
                  <b-td v-if="detailsData.city_corporation_id">{{ getCityCorporationName(detailsData.city_corporation_id) }}</b-td>
              </b-tr>
              <b-tr>
                  <b-th v-if="detailsData.address_bn && detailsData.address_en">{{ $t('globalTrans.address') }}</b-th>
                  <b-td v-if="detailsData.address_bn && detailsData.address_en">{{ ($i18n.locale=='bn') ? detailsData.address_bn : detailsData.address_en }}</b-td>
              </b-tr>
          </b-table-simple>
          <b-table-simple bordered>
            <b-tr>
                <b-th colspan="6" class="text-center">{{ $t('educational_management.permanent_address') }}</b-th>
            </b-tr>
            <b-tr>
              <b-th v-if="detailsData.per_area_type_id">{{ $t('org_pro.area_type') }}</b-th>
              <b-td v-if="detailsData.per_area_type_id">{{ getAreaType(detailsData.per_area_type_id) }}</b-td>
              <b-th v-if="detailsData.per_division_id">{{ $t('org_pro_division.division') }}</b-th>
              <b-td v-if="detailsData.per_division_id">{{ getDivisionName(detailsData.per_division_id) }}</b-td>
              <b-th v-if="detailsData.per_district_id">{{ $t('org_pro_district.district') }}</b-th>
              <b-td v-if="detailsData.per_district_id">{{ getDistrictName(detailsData.per_district_id) }}</b-td>
          </b-tr>
          <b-tr>
              <b-th v-if="detailsData.per_upazila_id">{{ $t('org_pro_upazilla.upazilla') }}</b-th>
              <b-td v-if="detailsData.per_upazila_id">{{ getUpazilaName(detailsData.per_upazila_id) }}</b-td>
              <b-th v-if="detailsData.per_union_id">{{ $t('org_pro_union.union') }}</b-th>
              <b-td v-if="detailsData.per_union_id">{{ getUnionName(detailsData.per_union_id) }}</b-td>
              <b-th v-if="detailsData.per_city_corporation_id">{{ $t('org_pro.city_corporation') }}</b-th>
              <b-td v-if="detailsData.per_city_corporation_id">{{ getCityCorporationName(detailsData.per_city_corporation_id) }}</b-td>
          </b-tr>
          <b-tr>
              <b-th v-if="detailsData.per_address_en && detailsData.per_address_bn">{{ $t('globalTrans.address') }}</b-th>
              <b-td v-if="detailsData.per_address_en && detailsData.per_address_bn">{{ ($i18n.locale=='bn') ? detailsData.per_address_bn : detailsData.per_address_en }}</b-td>
          </b-tr>
          </b-table-simple>
          </iq-card>
        </b-col>
      </b-row>
    </b-container>
</template>
<script>
export default {
  name: 'FormLayout',
  props: ['item'],
  created () {
    this.detailsData = this.item
  },
  data () {
    return {
      detailsData: {},
      slOffset: 1
    }
  },
  computed: {
    localeLang () {
      return this.$i18n.locale
    },
    listData () {
      const objectData = this.item
      return objectData.filter(data => data.id === this.item.id)
    }
  },
  methods: {
    getAreaType (status) {
      if (status === 1) {
        return this.$i18n.locale === 'en' ? 'City Corpoation' : 'সিটি কর্পোরেশন'
      } else if (status === 2) {
        return this.$i18n.locale === 'en' ? 'Pauroshoba' : 'পৌরসভা'
      } else if (status === 3) {
        return this.$i18n.locale === 'en' ? 'Union' : 'ইউনিয়ন'
      }
    },
    getDivisionName (divisionId) {
      const obj = this.$store.state.commonObj.divisionList.find(el => el.value === parseInt(divisionId))
      if (obj !== 'undefinded') {
        return this.$i18n.locale === 'en' ? obj.text_en : obj.text_bn
      }
    },
    getDistrictName (districtId) {
      const obj = this.$store.state.commonObj.districtList.find(el => el.value === parseInt(districtId))
      if (obj !== 'undefinded') {
        return this.$i18n.locale === 'en' ? obj.text_en : obj.text_bn
      }
    },
    getUpazilaName (upazilaId) {
      const obj = this.$store.state.commonObj.upazilaList.find(el => el.value === parseInt(upazilaId))
      if (obj !== 'undefinded') {
        return this.$i18n.locale === 'en' ? obj.text_en : obj.text_bn
      }
    },
    getUnionName (upazilaId) {
      const obj = this.$store.state.commonObj.unionList.find(el => el.value === parseInt(upazilaId))
      if (obj !== 'undefinded') {
        return this.$i18n.locale === 'en' ? obj.text_en : obj.text_bn
      }
    },
    getCityCorporationName (upazilaId) {
      const obj = this.$store.state.commonObj.cityCorporationList.find(el => el.value === parseInt(upazilaId))
      if (obj !== 'undefinded') {
        return this.$i18n.locale === 'en' ? obj.text_en : obj.text_bn
      }
    }
  }
}
</script>
<style>
  .hidden_header {
    display: none
  }
  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
  .report-name{
    font-weight: bold !important;
    text-transform: uppercase;
  }
  .my-btn{
    padding: 2px !important;
  }
</style>
