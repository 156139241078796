<template>
    <b-container fluid>
        <b-row>
            <b-col lg="12" sm="12">
                <iq-card>
                    <template v-slot:body>
                        <b-overlay :show="loading">
                            <b-row>
                                <b-col lg="12" sm="12">
                                    <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                                      <b-form @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset" >
                                          <b-row>
                                            <b-col lg="6" sm="12">
                                              <ValidationProvider name="Name (En)" vid="name" rules="required">
                                                <b-form-group
                                                  class="row"
                                                  label-cols-sm="4"
                                                  label-for="name"
                                                  slot-scope="{ valid, errors }"
                                                >
                                                  <template v-slot:label>
                                                  {{ $t('globalTrans.name_en')}} <span class="text-danger">*</span>
                                                    </template>
                                                  <b-form-input
                                                    v-model="machineryFarmer.name"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                  <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                  </div>
                                                </b-form-group>
                                              </ValidationProvider>
                                            </b-col>
                                            <b-col lg="6" sm="12">
                                              <ValidationProvider name="Name (Bn)" vid="name_bn" rules="required">
                                                <b-form-group
                                                  class="row"
                                                  label-cols-sm="4"
                                                  label-for="name_bn"
                                                  slot-scope="{ valid, errors }"
                                                >
                                                  <template v-slot:label>
                                                  {{ $t('globalTrans.name_bn')}} <span class="text-danger">*</span>
                                                  </template>
                                                  <b-form-input
                                                    id="name_bn"
                                                    v-model="machineryFarmer.name_bn"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                  <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                  </div>
                                                </b-form-group>
                                              </ValidationProvider>
                                            </b-col>
                                            <b-col lg="6" sm="12">
                                              <ValidationProvider name="Father Name (En)" vid="father_name" rules="required">
                                                <b-form-group
                                                  class="row"
                                                  label-cols-sm="4"
                                                  label-for="father_name"
                                                  slot-scope="{ valid, errors }"
                                                >
                                                  <template v-slot:label>
                                                  {{ $t('common_config.farmer_father_name_en')}} <span class="text-danger">*</span>
                                                    </template>
                                                  <b-form-input
                                                    v-model="machineryFarmer.father_name"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                  <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                  </div>
                                                </b-form-group>
                                              </ValidationProvider>
                                            </b-col>
                                            <b-col lg="6" sm="12">
                                              <ValidationProvider name="Father Name (Bn)" vid="father_name_bn" rules="required">
                                                <b-form-group
                                                  class="row"
                                                  label-cols-sm="4"
                                                  label-for="father_name_bn"
                                                  slot-scope="{ valid, errors }"
                                                >
                                                  <template v-slot:label>
                                                  {{ $t('common_config.farmer_father_name_bn')}} <span class="text-danger">*</span>
                                                  </template>
                                                  <b-form-input
                                                    id="father_name_bn"
                                                    v-model="machineryFarmer.father_name_bn"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                  <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                  </div>
                                                </b-form-group>
                                              </ValidationProvider>
                                            </b-col>
                                            <b-col lg="6" sm="12">
                                              <ValidationProvider name="Mother Name (En)" vid="mother_name_en">
                                                <b-form-group
                                                  class="row"
                                                  label-cols-sm="4"
                                                  label-for="mother_name_en"
                                                  slot-scope="{ valid, errors }"
                                                >
                                                  <template v-slot:label>
                                                  {{ $t('admission_form.mother_name_en')}}
                                                    </template>
                                                  <b-form-input
                                                    v-model="machineryFarmer.mother_name"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                  <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                  </div>
                                                </b-form-group>
                                              </ValidationProvider>
                                            </b-col>
                                            <b-col lg="6" sm="12">
                                              <ValidationProvider name="Mother Name (Bn)" vid="mother_name_bn">
                                                <b-form-group
                                                  class="row"
                                                  label-cols-sm="4"
                                                  label-for="mother_name_bn"
                                                  slot-scope="{ valid, errors }"
                                                >
                                                  <template v-slot:label>
                                                  {{ $t('admission_form.mother_name_bn')}}
                                                  </template>
                                                  <b-form-input
                                                    id="father_name_bn"
                                                    v-model="machineryFarmer.mother_name_bn"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                  <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                  </div>
                                                </b-form-group>
                                              </ValidationProvider>
                                            </b-col>

                                            <b-col lg="6" sm="12">
                                              <ValidationProvider name="Gender" vid="gender_id" rules="required|min_value:1">
                                                <b-form-group class="row" label-cols-sm="4" label-for="gender_id" slot-scope="{ valid, errors }">
                                                  <template v-slot:label>
                                                    {{ $t('common_config.farmer_gender') }} <span class="text-danger">*</span>
                                                  </template>
                                                    <b-form-select
                                                        plain
                                                        v-model="machineryFarmer.gender_id"
                                                        :options="genderList"
                                                        id="gender_id"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        >
                                                        <template v-slot:first>
                                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                        </template>
                                                    </b-form-select>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                                </b-form-group>
                                              </ValidationProvider>
                                            </b-col>
                                            <b-col lg="6" sm="12">
                                              <ValidationProvider name="Date of birth" vid="date_of_birth" rules="required">
                                                <b-form-group
                                                  class="row"
                                                  label-cols-sm="4"
                                                  label-for="date_of_birth"
                                                  slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{$t('externalUserIrrigation.date_of_birth')}} <span class="text-danger">*</span>
                                                </template>
                                                  <flat-pickr class="form-control"
                                                    :config="{ static: true }"
                                                    v-model="machineryFarmer.date_of_birth"
                                                    :placeholder="$t('globalTrans.select_date')"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    :class="errors[0] ? 'is-invalid' : ''"
                                                  ></flat-pickr>
                                                  <div class="invalid-feedback d-block">
                                                      {{ errors[0] }}
                                                  </div>
                                                </b-form-group>
                                              </ValidationProvider>
                                            </b-col>
                                            <b-col lg="6" sm="12">
                                              <ValidationProvider name="NID" vid="nid_no" rules="required|max:17">
                                                <b-form-group class="row" label-cols-sm="4" label-for="nid" slot-scope="{ valid, errors }">
                                                  <template v-slot:label>
                                                    {{$t('admission_form.nid_no')}} <span class="text-danger">*</span>
                                                  </template>
                                                  <b-form-input
                                                      type="number"
                                                      id="nid_no"
                                                      v-model="machineryFarmer.nid_no"
                                                      oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                                      :state="errors[0] ? false : (valid ? true : null)"
                                                  ></b-form-input>
                                                  <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                  </div>
                                                </b-form-group>
                                              </ValidationProvider>
                                            </b-col>
                                            <b-col lg="6" sm="12">
                                              <ValidationProvider name="Mobile No" vid="mobile_no" rules="required|numeric|min:11" >
                                                <b-form-group
                                                  class="row"
                                                  label-cols-sm="4"
                                                  label-for="mobile_no"
                                                  slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                  {{ $t('user.mobile_no')}} <span class="text-danger">*</span>
                                                  </template>
                                                  <b-form-input
                                                    id="mobile_no"
                                                    v-model="machineryFarmer.mobile_no"
                                                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                  <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                  </div>
                                                </b-form-group>
                                              </ValidationProvider>
                                            </b-col>
                                            <b-col lg="6" sm="12">
                                              <ValidationProvider name="Email" vid="email">
                                                <b-form-group
                                                  class="row"
                                                  label-cols-sm="4"
                                                  :label="$t('machineryFarmer.email')"
                                                  label-for="email"
                                                  slot-scope="{ valid, errors }"
                                                >
                                                  <template v-slot:label>
                                                  {{ $t('user.email')}}
                                                  </template>
                                                  <b-form-input
                                                    id="email"
                                                    v-model="machineryFarmer.email"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                  <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                  </div>
                                                </b-form-group>
                                              </ValidationProvider>
                                            </b-col>
                                            <b-col lg="6" sm="12">
                                              <ValidationProvider name="Password" :rules="passwordRules">
                                                <b-form-group
                                                  class="row"
                                                  label-cols-sm="4"
                                                  label-for="password"
                                                  slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                  {{ $t('user.password')}} <span v-if="!machineryFarmer.id" class="text-danger">*</span>
                                                  </template>
                                                  <b-form-input
                                                    type="password"
                                                    id="password"
                                                    v-model="machineryFarmer.password"
                                                    autocomplete="new-password"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                  <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                  </div>
                                                </b-form-group>
                                              </ValidationProvider>
                                            </b-col>
                                            <b-col lg="6" sm="12">
                                              <ValidationProvider name="Repeat Password" vid="password_confirmation" :rules="passwordConfirmRules">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="password_confirmation"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{ $t('user.password_confirmation')}}<span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-input
                                                    type="password"
                                                    id="password_confirmation"
                                                    v-model="machineryFarmer.password_confirmation"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col lg="6" sm="12">
                                              <ValidationProvider name="Attachment" vid="attachment">
                                                <b-form-group class="row" label-cols-sm="4" label-for="attachment" slot-scope="{ valid, errors }">
                                                  <template v-slot:label>
                                                    {{ $t('farm_config.attachment') }}
                                                  </template>
                                                  <b-form-file
                                                    id="attachment"
                                                    v-model="machineryFarmer.attachment"
                                                    :placeholder="$t('common_config.file_chosen')"
                                                    @change="onFileChange"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                  </b-form-file>
                                                  <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                  </div>
                                                </b-form-group>
                                              </ValidationProvider>
                                            </b-col>
                                          </b-row>
                                          <template>
                                            <div style="font-size:12px; background-color: #337982; padding:6px">
                                                <h5 class="text-white text-center"> {{ $t('admission_form.add_info') }}</h5>
                                            </div>
                                            </template>
                                            <b-row>
                                                <b-col lg="6" sm="12" class="mt-3">
                                                    <ValidationProvider name="Area Type" vid="area_type_id" rules="required|min_value:1">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="4"
                                                            label-for="area_type_id "
                                                            slot-scope="{ valid, errors }"
                                                            >
                                                            <template v-slot:label>
                                                                {{$t('org_pro.area_type')}} <span class="text-danger">*</span>
                                                            </template>
                                                            <b-form-select
                                                            plain
                                                            v-model="machineryFarmer.area_type_id"
                                                            id="area_type_id"
                                                            :options="areaTypeList"
                                                            @change="getAreaTypeData(machineryFarmer.area_type_id)"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                            >
                                                            <template v-slot:first>
                                                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                            </template>
                                                            </b-form-select>
                                                            <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                          </b-row>
                                          <b-row>
                                            <b-col lg="6" sm="12" v-show="ItemShow">
                                                <ValidationProvider name="Division" vid="division_id" rules="required|min_value:1">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="division_id"
                                                        slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                        {{ $t('org_pro_division.division')}} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-select
                                                        plain
                                                        v-model="machineryFarmer.division_id"
                                                        :options="divisionList"
                                                        id="division_id"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        >
                                                        <template v-slot:first>
                                                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                        </template>
                                                        </b-form-select>
                                                        <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col lg="6" sm="12" v-show="ItemShow">
                                                <ValidationProvider name="District" vid="district_id" rules="required|min_value:1">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="district_id"
                                                        slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                        {{ $t('org_pro_district.district')}} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-select
                                                        plain
                                                        v-model="machineryFarmer.district_id"
                                                        :options="districtList"
                                                        id="district_id"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        >
                                                        <template v-slot:first>
                                                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                        </template>
                                                        </b-form-select>
                                                        <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col lg="6" sm="12" v-show="PauroshobaItemShow || UnionItemShow">
                                                <ValidationProvider name="Upazila" vid="upazila_id">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="upazila_id"
                                                        slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                        {{ $t('org_pro_upazilla.upazilla')}} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-select
                                                        plain
                                                        v-model="machineryFarmer.upazilla_id"
                                                        :options="upazilaList"
                                                        id="upazila_id"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        >
                                                        <template v-slot:first>
                                                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                        </template>
                                                        </b-form-select>
                                                        <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col lg="6" sm="12" v-show="UnionItemShow">
                                                <ValidationProvider name="Union" vid="union_id">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="union_id"
                                                        slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                        {{ $t('org_pro_union.union')}} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-select
                                                        plain
                                                        v-model="machineryFarmer.union_id"
                                                        :options="unionList"
                                                        id="union_id"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        >
                                                        <template v-slot:first>
                                                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                        </template>
                                                        </b-form-select>
                                                        <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col lg="6" sm="12" v-show="CityCorpItemShow">
                                                <ValidationProvider name="City Corporation" vid="city_corporation_id">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="city_corporation_id"
                                                        slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                            {{ $t('org_pro.city_corporation') }} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-select
                                                        plain
                                                        v-model="machineryFarmer.city_corporation_id"
                                                        :options="cityCorporationList"
                                                        id="city_corporation_id"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        >
                                                        <template v-slot:first>
                                                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                        </template>
                                                        </b-form-select>
                                                        <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col lg="6" sm="12" v-show="PauroshobaItemShow">
                                                <ValidationProvider name="Pauroshoba" vid="pauroshoba_id">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="pauroshoba_id"
                                                        slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                            {{ $t('org_pro.pauroshoba') }} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-select
                                                        plain
                                                        v-model="machineryFarmer.pauroshoba_id"
                                                        :options="pauroshobaList"
                                                        id="pauroshoba_id"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        >
                                                        <template v-slot:first>
                                                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                        </template>
                                                        </b-form-select>
                                                        <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col lg="6" sm="12" v-show="ItemShow">
                                                <ValidationProvider name="Address" vid="address_en">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="address_en"
                                                        slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                            {{ $t('farm_config.address') }} {{ ' ' + $t('globalTrans.enn') }}
                                                        </template>
                                                        <b-form-input
                                                          type="text"
                                                          id="address_en"
                                                          v-model="machineryFarmer.address_en"
                                                          :state="errors[0] ? false : (valid ? true : null)"
                                                        ></b-form-input>
                                                        <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col lg="6" sm="12" v-show="ItemShow">
                                                <ValidationProvider name="Address" vid="address_bn">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="address_bn"
                                                        slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                            {{ $t('farm_config.address') }} {{ ' ' + $t('globalTrans.bnn') }}
                                                        </template>
                                                        <b-form-input
                                                          type="text"
                                                          id="address_bn"
                                                          v-model="machineryFarmer.address_bn"
                                                          :state="errors[0] ? false : (valid ? true : null)"
                                                        ></b-form-input>
                                                        <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                          </b-row>
                                          <template>
                                            <div style="font-size:12px; background-color: #337982; padding:6px">
                                                <h5 class="text-white text-center"> {{ $t('educational_management.permanent_address') }}</h5>
                                            </div>
                                            </template>
                                          <b-row>
                                            <b-col lg="6"  sm="12">
                                                <ValidationProvider>
                                                    <b-form-group
                                                        class="row"
                                                        :label="$t('educational_management.present_business_address')"
                                                        label-cols-sm="4"
                                                        label-for="present_address"
                                                        slot-scope="{ errors }"
                                                    >
                                                        <div>
                                                        <b-form-checkbox
                                                            class="mt-2"
                                                            id="same_as_present_address"
                                                            v-model="same_as_present_address"
                                                            :value="true"
                                                            :unchecked-value="false"
                                                        >
                                                            {{$t('admission_form.same')}}
                                                        </b-form-checkbox>
                                                        </div>
                                                        <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                          </b-row>
                                          <b-row>
                                            <b-col lg="6" sm="12">
                                                <ValidationProvider name="Area Type" vid="per_area_type_id" rules="required|min_value:1">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="per_area_type_id "
                                                        slot-scope="{ valid, errors }"
                                                    >
                                                        <template v-slot:label>
                                                            {{$t('org_pro.area_type')}} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-select
                                                            plain
                                                            :disabled="same_as_present_address === 1"
                                                            v-model="machineryFarmer.per_area_type_id"
                                                            id="per_area_type_id"
                                                            :options="areaTypeList"
                                                            @change="getAreaTypePerData(machineryFarmer.per_area_type_id)"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                        >
                                                            <template v-slot:first>
                                                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                            </template>
                                                        </b-form-select>
                                                        <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                          </b-row>
                                          <b-row>
                                              <b-col lg="6" sm="12" v-show="ItemShowPer">
                                                <ValidationProvider name="Division" vid="per_division_id" rules="required|min_value:1">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="per_division_id"
                                                        slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                        {{ $t('org_pro_division.division')}} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-select
                                                        plain
                                                        :disabled="same_as_present_address === 1"
                                                        v-model="machineryFarmer.per_division_id"
                                                        :options="divisionPerList"
                                                        id="per_division_id"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        >
                                                        <template v-slot:first>
                                                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                        </template>
                                                        </b-form-select>
                                                        <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                              </b-col>
                                              <b-col lg="6" sm="12" v-show="ItemShowPer">
                                                  <ValidationProvider name="District" vid="per_district_id" rules="required|min_value:1">
                                                      <b-form-group
                                                          class="row"
                                                          label-cols-sm="4"
                                                          label-for="per_district_id"
                                                          slot-scope="{ valid, errors }"
                                                          >
                                                          <template v-slot:label>
                                                          {{ $t('org_pro_district.district')}} <span class="text-danger">*</span>
                                                          </template>
                                                          <b-form-select
                                                          plain
                                                          :disabled="same_as_present_address === 1"
                                                          v-model="machineryFarmer.per_district_id"
                                                          :options="districtPerList"
                                                          id="per_district_id"
                                                          :state="errors[0] ? false : (valid ? true : null)"
                                                          >
                                                          <template v-slot:first>
                                                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                          </template>
                                                          </b-form-select>
                                                          <div class="invalid-feedback">
                                                          {{ errors[0] }}
                                                          </div>
                                                      </b-form-group>
                                                  </ValidationProvider>
                                              </b-col>
                                              <b-col lg="6" sm="12" v-show="PauroshobaItemPerShow || UnionItemPerShow">
                                                    <ValidationProvider name="Upazila" vid="per_upazila_id">
                                                      <b-form-group
                                                          class="row"
                                                          label-cols-sm="4"
                                                          label-for="per_upazila_id"
                                                          slot-scope="{ valid, errors }"
                                                          >
                                                          <template v-slot:label>
                                                          {{ $t('org_pro_upazilla.upazilla')}} <span class="text-danger">*</span>
                                                          </template>
                                                          <b-form-select
                                                          plain
                                                          :disabled="same_as_present_address === 1"
                                                          v-model="machineryFarmer.per_upazilla_id"
                                                          :options="upazilaPerList"
                                                          id="per_upazila_id"
                                                          :state="errors[0] ? false : (valid ? true : null)"
                                                          >
                                                          <template v-slot:first>
                                                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                          </template>
                                                          </b-form-select>
                                                          <div class="invalid-feedback">
                                                          {{ errors[0] }}
                                                          </div>
                                                      </b-form-group>
                                                  </ValidationProvider>
                                              </b-col>
                                              <b-col lg="6" sm="12" v-show="UnionItemPerShow">
                                                  <ValidationProvider name="Union" vid="per_union_id">
                                                      <b-form-group
                                                          class="row"
                                                          label-cols-sm="4"
                                                          label-for="per_union_id"
                                                          slot-scope="{ valid, errors }"
                                                          >
                                                          <template v-slot:label>
                                                          {{ $t('org_pro_union.union')}} <span class="text-danger">*</span>
                                                          </template>
                                                          <b-form-select
                                                          plain
                                                          :disabled="same_as_present_address === 1"
                                                          v-model="machineryFarmer.per_union_id"
                                                          :options="unionPerList"
                                                          id="per_union_id"
                                                          :state="errors[0] ? false : (valid ? true : null)"
                                                          >
                                                          <template v-slot:first>
                                                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                          </template>
                                                          </b-form-select>
                                                          <div class="invalid-feedback">
                                                          {{ errors[0] }}
                                                          </div>
                                                      </b-form-group>
                                                  </ValidationProvider>
                                              </b-col>
                                              <b-col lg="6" sm="12" v-show="CityCorpItemPerShow">
                                                    <ValidationProvider name="City Corporation" vid="per_city_corporation_id">
                                                      <b-form-group
                                                          class="row"
                                                          label-cols-sm="4"
                                                          label-for="per_city_corporation_id"
                                                          slot-scope="{ valid, errors }"
                                                          >
                                                          <template v-slot:label>
                                                              {{ $t('org_pro.city_corporation') }} <span class="text-danger">*</span>
                                                          </template>
                                                          <b-form-select
                                                          plain
                                                          :disabled="same_as_present_address === 1"
                                                          v-model="machineryFarmer.per_city_corporation_id"
                                                          :options="cityCorporationPerList"
                                                          id="per_city_corporation_id"
                                                          :state="errors[0] ? false : (valid ? true : null)"
                                                          >
                                                          <template v-slot:first>
                                                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                          </template>
                                                          </b-form-select>
                                                          <div class="invalid-feedback">
                                                          {{ errors[0] }}
                                                          </div>
                                                      </b-form-group>
                                                  </ValidationProvider>
                                              </b-col>
                                              <b-col lg="6" sm="12" v-show="PauroshobaItemPerShow">
                                                  <ValidationProvider name="Pauroshoba" vid="per_pauroshoba_id">
                                                      <b-form-group
                                                          class="row"
                                                          label-cols-sm="4"
                                                          label-for="per_pauroshoba_id"
                                                          slot-scope="{ valid, errors }"
                                                          >
                                                          <template v-slot:label>
                                                              {{ $t('org_pro.pauroshoba') }} <span class="text-danger">*</span>
                                                          </template>
                                                          <b-form-select
                                                          plain
                                                          :disabled="same_as_present_address === 1"
                                                          v-model="machineryFarmer.per_pauroshoba_id"
                                                          :options="pauroshobaPerList"
                                                          id="per_pauroshoba_id"
                                                          :state="errors[0] ? false : (valid ? true : null)"
                                                          >
                                                          <template v-slot:first>
                                                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                          </template>
                                                          </b-form-select>
                                                          <div class="invalid-feedback">
                                                          {{ errors[0] }}
                                                          </div>
                                                      </b-form-group>
                                                  </ValidationProvider>
                                              </b-col>
                                              <b-col lg="6" sm="12" v-show="ItemShowPer">
                                                  <ValidationProvider name="address" vid="per_address_en">
                                                      <b-form-group
                                                          class="row"
                                                          label-cols-sm="4"
                                                          label-for="per_address_en"
                                                          slot-scope="{ valid, errors }"
                                                          >
                                                          <template v-slot:label>
                                                              {{ $t('farm_config.address') }} {{ ' ' + $t('globalTrans.enn') }}
                                                          </template>
                                                          <b-form-input
                                                            type="text"
                                                            id="per_address_en"
                                                            :disabled="same_as_present_address === 1"
                                                            v-model="machineryFarmer.per_address_en"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                          ></b-form-input>
                                                          <div class="invalid-feedback">
                                                          {{ errors[0] }}
                                                          </div>
                                                      </b-form-group>
                                                  </ValidationProvider>
                                              </b-col>
                                              <b-col lg="6" sm="12" v-show="ItemShowPer">
                                                  <ValidationProvider name="Address" vid="per_address_bn">
                                                      <b-form-group
                                                          class="row"
                                                          label-cols-sm="4"
                                                          label-for="per_address_bn"
                                                          slot-scope="{ valid, errors }"
                                                          >
                                                          <template v-slot:label>
                                                              {{ $t('farm_config.address') }} {{ ' ' + $t('globalTrans.bnn') }}
                                                          </template>
                                                          <b-form-input
                                                            type="text"
                                                            id="per_block_bn"
                                                            :disabled="same_as_present_address === 1"
                                                            v-model="machineryFarmer.per_address_bn"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                          ></b-form-input>
                                                          <div class="invalid-feedback">
                                                          {{ errors[0] }}
                                                          </div>
                                                      </b-form-group>
                                                  </ValidationProvider>
                                              </b-col>
                                          </b-row>
                                          <div class="row">
                                              <div class="col-sm-3"></div>
                                              <div class="col text-right">
                                                  <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                                                  &nbsp;
                                                  <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                                              </div>
                                          </div>
                                      </b-form>
                                    </ValidationObserver>
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { machineryFarmerStore, machineryFarmerUpdate } from '../../api/routes'
// import flatpickr from 'flatpickr'

export default {
    props: ['id'],
    components: {
        ValidationObserver,
        ValidationProvider
    },
    created () {
        if (this.id) {
            const tmp = this.getmachineryFarmerData()
            // this.machineryFarmer = Object.assign(tmp, { org_id: parseInt(tmp.org_id) })
            this.machineryFarmer = tmp
            this.getAreaTypeData(this.machineryFarmer.area_type_id)
            this.getAreaTypePerData(this.machineryFarmer.per_area_type_id)
            if (this.machineryFarmer.is_same === 0) {
              this.same_as_present_address = true
            } else {
              this.same_as_present_address = false
            }
        }
    },
    data () {
        return {
            loading: false,
            saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
            machineryFarmer: {
                gender_id: 0,
                name: '',
                name_bn: '',
                father_name: '',
                father_name_bn: '',
                mother_name: '',
                mother_name_bn: '',
                date_of_birth: '',
                nid_no: '',
                email: '',
                mobile_no: '',
                area_type_id: 0,
                division_id: 0,
                district_id: 0,
                upazilla_id: 0,
                city_corporation_id: 0,
                pauroshoba_id: 0,
                union_id: 0,
                address_en: '',
                address_bn: '',
                is_same: 2,
                per_area_type_id: 0,
                per_division_id: 0,
                per_district_id: 0,
                per_upazilla_id: 0,
                per_city_corporation_id: 0,
                per_pauroshoba_id: 0,
                per_union_id: 0,
                per_address_en: '',
                per_address_bn: '',
                attachment: []
            },
            attachmentFile: [],
            photoFile: [],
            districtList: [],
            upazilaList: [],
            unionList: [],
            cityCorporationList: [],
            pauroshobaList: [],
            districtPerList: [],
            upazilaPerList: [],
            unionPerList: [],
            cityCorporationPerList: [],
            pauroshobaPerList: [],
            showIdType: 0,
            ItemShow: false,
            inputFieldShow: false,
            ItemShowPer: false,
            CityCorpItemShow: false,
            CityCorpItemPerShow: false,
            PauroshobaItemShow: false,
            PauroshobaItemPerShow: false,
            UnionItemShow: false,
            UnionItemPerShow: false,
            same_as_present_address: false,
            same_as_mobile_number: false,
            genderList: [
              { value: 1, text: this.$t('common_config.male') },
              { value: 2, text: this.$t('common_config.female') },
              { value: 3, text: this.$t('common_config.other') }
            ]
        }
    },
    mounted () {
      // flatpickr('.date-picker', {})
      core.index()
    },
    computed: {
      passwordRules: function () {
        return this.id ? '' : 'required|min:6'
      },
      passwordConfirmRules: function () {
        return this.id ? '' : 'required|min:6'
      },
      divisionList: function () {
          return this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
      },
      divisionPerList: function () {
          return this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
      },
      areaTypeList: function () {
        const list = [
          { value: 1, text: this.$i18n.locale === 'bn' ? 'সিটি কর্পোরেশন' : 'City Corpoation' },
          { value: 2, text: this.$i18n.locale === 'bn' ? 'পৌরসভা' : 'Pauroshoba' },
          { value: 3, text: this.$i18n.locale === 'bn' ? 'ইউনিয়ন' : 'Union' }
        ]
        return list
      }
    },
    watch: {
        'machineryFarmer.division_id': function (newVal, oldVal) {
            this.districtList = this.getDistrictList(newVal)
        },
        'machineryFarmer.per_division_id': function (newVal, oldVal) {
            this.districtPerList = this.getDistrictPerList(newVal)
        },
        'machineryFarmer.district_id': function (newVal, oldVal) {
            this.cityCorporationList = this.getCityCorporationList(newVal)
            this.upazilaList = this.getUpazilaList(newVal)
        },
        'machineryFarmer.per_district_id': function (newVal, oldVal) {
            this.cityCorporationPerList = this.getCityCorporationPerList(newVal)
            this.upazilaPerList = this.getUpazilaPerList(newVal)
        },
        'machineryFarmer.upazilla_id': function (newVal, oldVal) {
            this.unionList = this.getUnionList(newVal)
            this.pauroshobaList = this.getPauroshobaList(newVal)
        },
        'machineryFarmer.per_upazilla_id': function (newVal, oldVal) {
            this.unionPerList = this.getUnionPerList(newVal)
            this.pauroshobaPerList = this.getPauroshobaPerList(newVal)
        },
        same_as_present_address: function (newVal, oldVal) {
          if (newVal !== oldVal) {
            this.machineryFarmer.per_area_type_id = this.machineryFarmer.area_type_id
            this.machineryFarmer.per_city_corporation_id = this.machineryFarmer.city_corporation_id
            this.machineryFarmer.per_pauroshoba_id = this.machineryFarmer.pauroshoba_id
            this.machineryFarmer.per_division_id = this.machineryFarmer.division_id
            this.machineryFarmer.per_district_id = this.machineryFarmer.district_id
            this.machineryFarmer.per_upazilla_id = this.machineryFarmer.upazilla_id
            this.machineryFarmer.per_union_id = this.machineryFarmer.union_id
            this.machineryFarmer.per_address_en = this.machineryFarmer.address_en
            this.machineryFarmer.per_address_bn = this.machineryFarmer.address_en
            this.machineryFarmer.is_same = newVal
            this.ItemShowPer = true
            if (this.machineryFarmer.per_area_type_id === 1) {
              this.CityCorpItemPerShow = true
              this.PauroshobaItemPerShow = false
              this.UnionItemPerShow = false
            } else if (this.machineryFarmer.per_area_type_id === 2) {
              this.CityCorpItemPerShow = false
              this.PauroshobaItemPerShow = true
              this.UnionItemPerShow = false
            } else if (this.machineryFarmer.per_area_type_id === 3) {
              this.CityCorpItemPerShow = false
              this.PauroshobaItemPerShow = false
              this.UnionItemPerShow = true
            }
          }
        }
    },
    methods: {
      getmachineryFarmerData () {
        const tmpData = this.$store.state.list.find(item => item.id === this.id)
        return JSON.parse(JSON.stringify(tmpData))
      },
      onFileChange (e) {
        this.attachmentFile = e.target.files[0]
      },
      async saveData () {
          this.loading = true
          this.$store.dispatch('mutateCommonProperties', { loading: true })
          let result = null
          const loadingState = { loading: false, listReload: false }
          const config = {
            headers: { 'content-type': 'multipart/form-data' }
          }
          var formData = new FormData()
          Object.keys(this.machineryFarmer).map(key => {
            if (key === 'attachment') {
              formData.append(key, this.attachmentFile)
            } else {
              formData.append(key, this.machineryFarmer[key])
            }
          })
          if (this.id) {
            formData.append('_method', 'POST')
            result = await RestApi.postData(incentiveGrantServiceBaseUrl, `${machineryFarmerUpdate}/${this.id}`, formData, config)
          } else {
            result = await RestApi.postData(incentiveGrantServiceBaseUrl, machineryFarmerStore, formData, config)
          }

          loadingState.listReload = true
          this.$store.dispatch('mutateCommonProperties', loadingState)
          this.loading = false
          if (result.success) {
            this.$store.commit('incentiveGrant/mutateIncentiveGrantServiceProperties', { hasDropdownLoaded: false })
            this.$toast.success({
              title: this.$t('globalTrans.success'),
              message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
              color: '#D6E09B'
            })
            this.$bvModal.hide('modal-4')
          } else {
            this.$toast.error({
              title: 'Success',
              message: result.message,
              color: '#D6E09B'
            })
            this.$refs.form.setErrors(result.errors)
          }
      },
      getAreaTypeData (typeId) {
        this.ItemShow = true
        if (typeId === 1) {
          this.CityCorpItemShow = true
          this.PauroshobaItemShow = false
          this.UnionItemShow = false
        } else if (typeId === 2) {
          this.CityCorpItemShow = false
          this.PauroshobaItemShow = true
          this.UnionItemShow = false
        } else if (typeId === 3) {
          this.CityCorpItemShow = false
          this.PauroshobaItemShow = false
          this.UnionItemShow = true
        }
      },
      getAreaTypePerData (typeId2) {
        this.ItemShowPer = true
        if (typeId2 === 1) {
          this.CityCorpItemPerShow = true
          this.PauroshobaItemPerShow = false
          this.UnionItemPerShow = false
        } else if (typeId2 === 2) {
          this.CityCorpItemPerShow = false
          this.PauroshobaItemPerShow = true
          this.UnionItemPerShow = false
        } else if (typeId2 === 3) {
          this.CityCorpItemPerShow = false
          this.PauroshobaItemPerShow = false
          this.UnionItemPerShow = true
        }
      },
      getDistrictList (divisionId = null) {
        const districtList = this.$store.state.commonObj.districtList.filter(item => item.status === 0)
          if (divisionId) {
            return districtList.filter(district => district.division_id === divisionId)
          }
          return districtList
      },
      getDistrictPerList (divisionPerId = null) {
        const districtList = this.$store.state.commonObj.districtList.filter(item => item.status === 0)
        if (divisionPerId) {
          return districtList.filter(district => district.division_id === divisionPerId)
        }
        return districtList
      },
      getUpazilaList (districtId = null) {
        const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)
        if (districtId) {
          return upazilaList.filter(upazila => upazila.district_id === districtId)
        }
        return upazilaList
      },
      getUpazilaPerList (districtId = null) {
        const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)
          if (districtId) {
            return upazilaList.filter(upazila => upazila.district_id === districtId)
          }
          return upazilaList
      },
      getUnionList (upazilaId = null) {
        const unionList = this.$store.state.commonObj.unionList.filter(item => item.status === 0)
          if (upazilaId) {
            return unionList.filter(union => union.upazilla_id === upazilaId)
          }
          return unionList
      },
      getUnionPerList (upazilaId = null) {
        const unionList = this.$store.state.commonObj.unionList.filter(item => item.status === 0)
        if (upazilaId) {
          return unionList.filter(union => union.upazilla_id === upazilaId)
        }
        return unionList
      },
      getCityCorporationList (districtId) {
        const objectData = this.$store.state.commonObj.cityCorporationList.filter(item => item.status === 0 && item.district_id === districtId)
          return objectData.map((obj, key) => {
            if (this.$i18n.locale === 'bn') {
              return { value: obj.value, text: obj.text_bn }
            } else {
              return { value: obj.value, text: obj.text_en }
            }
          })
      },
      getCityCorporationPerList (districtId) {
        const objectData = this.$store.state.commonObj.cityCorporationList.filter(item => item.status === 0 && item.district_id === districtId)
          return objectData.map((obj, key) => {
              if (this.$i18n.locale === 'bn') {
                return { value: obj.value, text: obj.text_bn }
              } else {
                return { value: obj.value, text: obj.text_en }
              }
          })
      },
      getPauroshobaList (upazillaId = null) {
        const objectData = this.$store.state.commonObj.pauroshobaList.filter(item => item.upazilla_id === upazillaId)
          if (upazillaId) {
            return objectData.map((obj, key) => {
              if (this.$i18n.locale === 'bn') {
                return { value: obj.value, text: obj.text_bn }
              } else {
                return { value: obj.value, text: obj.text_en }
              }
            })
          }
      },
      getPauroshobaPerList (upazillaId = null) {
        const objectData = this.$store.state.commonObj.pauroshobaList.filter(item => item.upazilla_id === upazillaId)
        if (upazillaId) {
          return objectData.map((obj, key) => {
            if (this.$i18n.locale === 'bn') {
              return { value: obj.value, text: obj.text_bn }
            } else {
              return { value: obj.value, text: obj.text_en }
            }
          })
        }
      }
    }
}
</script>
