
<template>
    <b-container fluid>
        <iq-card>
            <template v-slot:headerTitle>
                <h4 class="card-title">{{ $t('common_config.farmer_information') }} {{ $t('farm_config.search') }}</h4>
            </template>
            <template v-slot:body>
                <b-row>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                <ValidationProvider name="Division" vid="division_id">
                    <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="division_id"
                        slot-scope="{ valid, errors }">
                        <template v-slot:label>
                        {{ $t('org_pro_division.division') }}
                        </template>
                        <b-form-select
                        plain
                        v-model="search.division_id"
                        :options="divisionList"
                        id="division_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                        :disabled="authLocation.division_id != 0"
                        >
                        <template v-slot:first>
                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                        {{ errors[0] }}
                        </div>
                    </b-form-group>
                </ValidationProvider>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <ValidationProvider name="District" vid="district_id">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="district_id"
                            slot-scope="{ valid, errors }">
                            <template v-slot:label>
                                {{ $t('org_pro_district.district') }}
                            </template>
                            <b-form-select
                                plain
                                v-model="search.district_id"
                                :options="districtList"
                                id="district_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                :disabled="authLocation.district_id != 0"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                                {{ errors[0] }}
                            </div>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <ValidationProvider name="Upazilla" vid="upazilla_id">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="upazilla_id"
                            slot-scope="{ valid, errors }">
                            <template v-slot:label>
                                {{ $t('org_pro_upazilla.upazilla') }}
                            </template>
                            <b-form-select
                                plain
                                v-model="search.upazilla_id"
                                :options="upazilaList"
                                id="upazilla_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                :disabled="authLocation.upazilla_id != 0"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                                {{ errors[0] }}
                            </div>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-form-group
                        class="row"
                        label-cols-sm="4"
                        :label="$t('globalTrans.name')"
                        label-for="name"
                        >
                        <b-form-input
                            plain
                            v-model="search.name"
                            id="name"
                            >
                        </b-form-input>
                    </b-form-group>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-form-group
                        class="row"
                        label-cols-sm="4"
                        :label="$t('common_config.farmer_mobile_name')"
                        label-for="mobile_no"
                        >
                        <b-form-input
                            plain
                            v-model="search.mobile_no"
                            id="mobile_no"
                            >
                        </b-form-input>
                    </b-form-group>
                </b-col>
                  <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search') }}</b-button>
                  </b-col>
                </b-row>
            </template>
        </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                      <h4 class="card-title">{{ $t('common_config.farmer_information') }} {{ $t('farm_config.list') }}</h4>
                    </template>
                    <template v-slot:headerAction>
                      <b-button variant="primary btn_add_new" v-b-modal.modal-4 @click="resetId">
                        <i class="fas fa-plus"></i>{{ $t('globalTrans.add_new') }}
                      </b-button>
                    </template>
                    <template v-slot:body>
                      <b-overlay :show="loadingState">
                        <b-row>
                            <b-col md="12" class="table-responsive">
                                <b-table  :emptyText="$t('globalTrans.noDataFound')" show-empty thead-class="table_head" striped bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                                    <template v-slot:cell(index)="data">
                                        {{ $n(data.index + pagination.slOffset) }}
                                    </template>
                                    <template v-slot:cell(status)="data">
                                        <span class="badge badge-success" v-if="data.item.status == 1">{{$t('globalTrans.active')}}</span>
                                        <span class="badge badge-danger" v-else>{{$t('globalTrans.inactive')}}</span>
                                    </template>
                                    <template v-slot:cell(mobile_no)="data">
                                      {{ ($i18n.locale == 'bn' ? '০': '0') + $n(data.item.mobile_no, { useGrouping: false }) }}
                                  </template>
                                  <template v-slot:cell(address)="data">
                                    {{ getAddress(data.item)}}
                                  </template>
                                  <template v-slot:cell(action)="data">
                                      <a href="javascript:" class="btn_table_action table_action_view" title="View Details" v-b-modal.modal-5 size="sm" @click="details(data.item)">
                                        <i class="fas fa-eye"></i>
                                      </a>
                                      <a href="javascript:" class="btn_table_action table_action_edit" title="Edit" v-b-modal.modal-4 size="sm" @click="edit(data.item)">
                                          <i class="ri-ball-pen-fill"></i>
                                      </a>
                                      <a href="javascript:" class="btn_table_action table_action_view" title="Status" size="sm" @click="remove(data.item)" v-if="data.item.status === 1">
                                          <i class="fas fa-toggle-on"></i>
                                      </a>
                                      <a href="javascript:" class="btn_table_action table_action_toggle" title="Status" size="sm" @click="remove(data.item)" v-if="data.item.status === 2">
                                        <i class="fa fa-toggle-off"></i>
                                      </a>
                                  </template>
                                </b-table>
                                <b-pagination
                                    align="center"
                                    v-model="pagination.currentPage"
                                    :per-page="pagination.perPage"
                                    :total-rows="pagination.totalRows"
                                    @input="searchData"
                                    />
                            </b-col>
                        </b-row>
                      </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
         <b-modal id="modal-5" size="lg" :title="$t('common_config.farmer_information') + ' ' + this.$t('globalTrans.details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <Details :item="item" :key="item"></Details>
        </b-modal>
        <b-modal id="modal-4" size="xl" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <Form :id="editItemId" :key="editItemId"/>
        </b-modal>
    </b-container>
</template>
<script>
import Details from './Details'
import Form from './Form'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { machineryFarmerList, farmMachineryToggleStatus } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    Form, Details
  },
  data () {
    return {
        districtList: [],
        upazilaList: [],
        search: {
            name: '',
            mobile_no: '',
            division_id: 0,
            district_id: 0,
            upazilla_id: 0
        },
        rows: [],
        item: '',
        getAreaType: [
            { value: 1, text_en: 'City Corpoation', text_bn: 'সিটি কর্পোরেশন' },
            { value: 2, text_en: 'Pauroshoba', text_bn: 'পৌরশোবা' },
            { value: 3, text_en: 'Union', text_bn: 'ইউনিয়ন' }
        ],
        authLocation: {
            division_id: 0,
            district_id: 0,
            upazilla_id: 0,
            upazilla_id_arr: []
        }
    }
  },
  mounted () {
  },
  computed: {
    divisionList: function () {
      return this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
    },
    formTitle () {
      return (this.editItemId === 0) ? this.$t('common_config.farmer_information') + ' ' + this.$t('globalTrans.add') : this.$t('common_config.farmer_information') + ' ' + this.$t('globalTrans.modify')
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
          { label: this.$t('globalTrans.name'), class: 'text-center' },
          { label: this.$t('user.email'), class: 'text-center' },
          { label: this.$t('user.mobile_no'), class: 'text-center' },
          { label: this.$t('globalTrans.address'), class: 'text-center' },
          // { label: this.$t('globalTrans.status'), class: 'text-center' },
          { label: this.$t('globalTrans.action'), class: 'text-center' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'name_bn' },
          { key: 'email' },
          { key: 'mobile_no' },
          { key: 'address' },
          // { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'name' },
          { key: 'email' },
          { key: 'mobile_no' },
          { key: 'address' },
          // { key: 'status' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
        })
    }
  },
  watch: {
    loadingState: function (newVal, oldVal) {
      if (newVal) {
        this.loadData()
      }
    },
    'search.division_id': function (newVal, oldVal) {
      this.districtList = this.getDistrictList(newVal)
    },
    'search.district_id': function (newVal, oldVal) {
      this.upazilaList = this.getUpazilaList(newVal)
    }
  },
  created () {
    this.setAuthLocationInfo()
    this.loadData()
  },
  methods: {
    setAuthLocationInfo () {
      let location = {
        division_id: 0,
        district_id: 0,
        upazilla_id: 0,
        upazilla_id_arr: []
      }
      const authUser = this.$store.state.Auth.authUser
      if (!(authUser.role_id === 1 || authUser.is_org_admin)) {
        const office = this.$store.state.commonObj.officeList.find(item => item.value === authUser.office_id)
        if (typeof office !== 'undefined') {
          this.upazillaIdList = []
          const upazillaIdArr = this.getUpazillaIdList(office.value)
          location = {
            division_id: office.division_id,
            district_id: office.district_id,
            upazilla_id: office.upazilla_id,
            upazilla_id_arr: upazillaIdArr
          }
        }
      }
      this.search = Object.assign({}, this.search, location)
      this.authLocation = Object.assign({}, this.authLocation, location)
    },
    getUpazillaIdList (officeId) {
      const office = this.$store.state.commonObj.officeList.find(item => item.value === officeId)
      if (typeof office !== 'undefined') {
        this.upazillaIdList.push(office.upazilla_id)
      }
      const childOfficeList = this.$store.state.commonObj.officeList.filter(item => item.parent_office_id === officeId)
      childOfficeList.forEach(childOffice => {
        this.getUpazillaIdList(childOffice.value)
      })
      return this.upazillaIdList.filter((value, index, self) => {
        return self.indexOf(value) === index
      })
    },
    getDistrictList (divisionId = null) {
      const districtList = this.$store.state.commonObj.districtList.filter(item => item.status === 0)
      if (divisionId) {
        return districtList.filter(district => district.division_id === divisionId)
      }
      return districtList
    },
    getUpazilaList (districtId = null) {
      const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)
      if (districtId) {
        return upazilaList.filter(upazila => upazila.district_id === districtId)
      }
      return upazilaList
    },
    searchData () {
      this.loadData()
    },
    edit (item) {
      this.editItemId = item.id
    },
    details (item) {
      this.item = item
    },
    remove (item) {
      this.changeStatus(incentiveGrantServiceBaseUrl, farmMachineryToggleStatus, item, 'incentive_grant')
    },
    loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.getData(incentiveGrantServiceBaseUrl, machineryFarmerList, params).then(response => {
        if (response.success) {
          this.$store.dispatch('setList', response.data.data)
          this.paginationData(response.data)
        } else {
          this.$store.dispatch('setList', [])
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    },
    getAddress (item) {
        const address = []
        if (item.area_type_id === 3) {
            const unionList = this.$store.state.commonObj.unionList
            const tmpUnion = unionList.find(union => union.value === item.union_id)
            if (this.$i18n.locale === 'bn') {
                address.push(tmpUnion ? tmpUnion.text_bn : '')
            } else {
                address.push(tmpUnion ? tmpUnion.text_en : '')
            }
            const upazilaList = this.$store.state.commonObj.upazilaList
            const tmpUpazila = upazilaList.find(upazila => upazila.value === item.upazilla_id)
            if (this.$i18n.locale === 'bn') {
                address.push(tmpUpazila ? tmpUpazila.text_bn : '')
            } else {
                address.push(tmpUpazila ? tmpUpazila.text_en : '')
            }
        } else if (item.area_type_id === 2) {
            const pauroshobaList = this.$store.state.commonObj.pauroshobaList
            const tmpPauroshoba = pauroshobaList.find(pauroshoba => pauroshoba.value === item.pauroshoba_id)
            if (this.$i18n.locale === 'bn') {
                address.push(tmpPauroshoba ? tmpPauroshoba.text_bn : '')
            } else {
                address.push(tmpPauroshoba ? tmpPauroshoba.text_en : '')
            }
        } else {
            const cityCorporationList = this.$store.state.commonObj.cityCorporationList
            const tmpCityCorporation = cityCorporationList.find(ccr => ccr.value === item.city_corporation_id)
            if (this.$i18n.locale === 'bn') {
                address.push(tmpCityCorporation ? tmpCityCorporation.text_bn : '')
            } else {
                address.push(tmpCityCorporation ? tmpCityCorporation.text_en : '')
            }
        }

        const districtList = this.$store.state.commonObj.districtList
        const tmpDistrict = districtList.find(district => district.value === item.district_id)
        if (this.$i18n.locale === 'bn') {
            address.push(tmpDistrict ? tmpDistrict.text_bn : '')
        } else {
            address.push(tmpDistrict ? tmpDistrict.text_en : '')
        }
        const divisionList = this.$store.state.commonObj.divisionList
        const tmpDivision = divisionList.find(division => division.value === item.division_id)
        if (this.$i18n.locale === 'bn') {
            address.push(tmpDivision ? tmpDivision.text_bn : '')
        } else {
            address.push(tmpDivision ? tmpDivision.text_en : '')
        }
        return address.join(', ')
    }
  }
}
</script>
